<template>
  <div class="header">
    <div class="header-container">
      <div class="content">
        <div class="left-area">
          <div class="left-menu-item">
            <div class="item-title">业务主管</div>
            <div class="item-desc">中国民间中医医药研究开发协会</div>
          </div>
          <div class="left-menu-item">
            <div class="item-title">主办单位</div>
            <div class="item-desc">北派筋骨辩证手法分会</div>
          </div>
          <div class="left-menu-item">
            <div class="item-title">承办单位</div>
            <div class="item-desc">北京本正堂中医药研究院</div>
          </div>
        </div>
        <div class="logo">
          <span>中医药健康服务人才<br>职业化培训平台</span>
          <img :src="logo" @click="goHome">
        </div>
        <div class="right-area">
          <img :src="qrCode"/>
          <div class="text">关注微信<br>联系客服</div>
        </div>
      </div>
    </div>
    <div class="nav-bar">
      <div class="nav-center">
        <div class="nav-item"
          v-for="item in navList"
          :key="item.path"
          :class="{'active-item': item.path == $route.path}"
          @click="navItemClick(item)"
          >{{item.title}}</div>
      </div>
    </div>
    <LoginModal ref="loginModalRef"/>
  </div>
</template>
<script>
import LoginModal from '@/components/account/components/login-modal.vue';
import { newsApi } from '@/api/news';

export default {
  name: '',
  components: {
    LoginModal
  },
  filters: {},
  mixins: [],
  props: {},
  async asyncData({ app, store }) {
    // 公告
    // 快讯
    const noticeResult = await newsApi.queryNewsList({ pageNum: 1, pageSize: 5 });
    const noticeList = noticeResult.data.list;
    return {
      noticeList
    };
  },
  data() {
    return {
    };
  },
  computed: {
    navList() {
      return [
        { title: '首页', path: '/', pcCenterFlag: false, needLogin: false },
        { title: '网站概况', path: '/website-info', pcCenterFlag: false},
        { title: '资讯中心', path: '/news/news-list', query: {type: 1002}, pcCenterFlag: false},
        { title: '在线教育', path: 'center/sign', pcCenterFlag: true, needLogin: true },
        { title: '证书查询', target: 'http://www.xhctcm.com/?page_id=6894' },
        { title: '合作交流', path: '/concat-us', pcCenterFlag: false},
        // { title: '联系我们', path: '/concat-us', pcCenterFlag: false},
      ]
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    bgImg() {
      return this.$store.state.user.systemPic.topBgImg;
    },
    bgImgStyle() {
      if (this.bgImg) {
        return {
          backgroundImage: `url(${this.bgImg})`
        };
      } else {
        return {};
      }
    },
    logo() {
      return this.$store.state.user.systemInfo.logo;
    },
    qrCode() {
      return this.$store.state.user.systemInfo.followQrCode
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.$store.dispatch('user/getSystemInfo');
    this.$store.dispatch('user/getSystemPic');
    console.log(this.$route)
  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    goHome() {
      this.$router.push({
        path: '/'
      });
    },
    navItemClick(item) {
      if (item.path == this.$route.fullPath) return;
      if (item.target) {
        window.open(item.target);
      }
      if (item.needLogin && this.lodash.isEmpty(this.userInfo)) {
        if (this.$route.path == '/') {
          this.$emit('login');
        } else {
          this.$refs.loginModalRef.showModal();
        }
        return;
      }
      if (item.pcCenterFlag) {
        this.$pcRouter.push({path: item.path});
      } else {
        console.log('跳转---------')
        this.$router.push({path: item.path, query: item.query || null});
        
      }
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  background: #fff;
  .header-container {
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 1200px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000000;
      .left-area {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        .left-menu-item {
          width: 100%;
          cursor: pointer;
          .item-title {
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
          }
          .item-desc {
            color: #13619f;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      .logo {
        flex: 1;
        display: flex;
        align-items: center;
        color: #000000;

        > img {
          width: 100px;
          height: 100px;
        }

        > span {
          margin-right: 28px;
          display: inline-block;
          text-align: center;
          font-size: 28px;
          line-height: 38px;
          font-weight: 700;
          color: #333;
        }
      }
      .right-area {
        width: 200px;
        height: 86px;
        border: 1px solid #ededed;
        border-radius: 4px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.10); 
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        img {
          width: 76px;
          height: 76px;

        }
        div {
          line-height: 25px;
          font-size: 18px;
          color: #1c2634;
        }
      }
    }
  }
  .nav-bar {
    width: 100%;
    height: 54px;
    background: #13619f;
    display: flex;
    justify-content: center;
    .nav-center {
      width: 1200px;
      height: 54px;
      display: flex;
      justify-content: space-between;
      .nav-item {
        height: 54px;
        line-height: 54px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        padding: 0 40px;
      }
      .active-item {
        background: #0f5287;
        font-weight: 700;
      }
    }
  }
}
</style>
