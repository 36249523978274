import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d47fa2d = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _2766ad2c = () => interopDefault(import('../pages/app-download/index.vue' /* webpackChunkName: "pages/app-download/index" */))
const _f27deb42 = () => interopDefault(import('../pages/book/index.vue' /* webpackChunkName: "pages/book/index" */))
const _a7d22812 = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _64fd30d2 = () => interopDefault(import('../pages/concat-us/index.vue' /* webpackChunkName: "pages/concat-us/index" */))
const _633a73ab = () => interopDefault(import('../pages/cooperation-exchange/index.vue' /* webpackChunkName: "pages/cooperation-exchange/index" */))
const _22b2435e = () => interopDefault(import('../pages/course/index.vue' /* webpackChunkName: "pages/course/index" */))
const _46384c82 = () => interopDefault(import('../pages/face-special/index.vue' /* webpackChunkName: "pages/face-special/index" */))
const _a3033b0a = () => interopDefault(import('../pages/feedback/index.vue' /* webpackChunkName: "pages/feedback/index" */))
const _7bc4d1d1 = () => interopDefault(import('../pages/forget-pass/index.vue' /* webpackChunkName: "pages/forget-pass/index" */))
const _630fe9c9 = () => interopDefault(import('../pages/in-development/index.vue' /* webpackChunkName: "pages/in-development/index" */))
const _7430a309 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2960663a = () => interopDefault(import('../pages/my-order/index.vue' /* webpackChunkName: "pages/my-order/index" */))
const _9e8a046e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _3ed9d819 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _d7453ff6 = () => interopDefault(import('../pages/shopping-cart/index.vue' /* webpackChunkName: "pages/shopping-cart/index" */))
const _68521f02 = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _3857da56 = () => interopDefault(import('../pages/website-info/index.vue' /* webpackChunkName: "pages/website-info/index" */))
const _2bea0673 = () => interopDefault(import('../pages/wx-login/index.vue' /* webpackChunkName: "pages/wx-login/index" */))
const _5e18de88 = () => interopDefault(import('../pages/about/detail-info/index.vue' /* webpackChunkName: "pages/about/detail-info/index" */))
const _57689f5c = () => interopDefault(import('../pages/agreement/privacy-policy-YAO_XUE.vue' /* webpackChunkName: "pages/agreement/privacy-policy-YAO_XUE" */))
const _1663857e = () => interopDefault(import('../pages/agreement/privacy-policy-ZHI_YE.vue' /* webpackChunkName: "pages/agreement/privacy-policy-ZHI_YE" */))
const _215a6b7a = () => interopDefault(import('../pages/agreement/user-agreement-YAO_XUE.vue' /* webpackChunkName: "pages/agreement/user-agreement-YAO_XUE" */))
const _3008e96d = () => interopDefault(import('../pages/agreement/user-agreement-ZHI_YE.vue' /* webpackChunkName: "pages/agreement/user-agreement-ZHI_YE" */))
const _8bcfe7fc = () => interopDefault(import('../pages/book/book-detail.vue' /* webpackChunkName: "pages/book/book-detail" */))
const _4d4ef065 = () => interopDefault(import('../pages/course/detail/index.vue' /* webpackChunkName: "pages/course/detail/index" */))
const _36c46de8 = () => interopDefault(import('../pages/h5/course-class/index.vue' /* webpackChunkName: "pages/h5/course-class/index" */))
const _da151036 = () => interopDefault(import('../pages/h5/forget-pass/index.vue' /* webpackChunkName: "pages/h5/forget-pass/index" */))
const _4e0c681d = () => interopDefault(import('../pages/h5/login/index.vue' /* webpackChunkName: "pages/h5/login/index" */))
const _a695eaf6 = () => interopDefault(import('../pages/h5/register/index.vue' /* webpackChunkName: "pages/h5/register/index" */))
const _490499df = () => interopDefault(import('../pages/news/news-info.vue' /* webpackChunkName: "pages/news/news-info" */))
const _e2c32462 = () => interopDefault(import('../pages/news/news-list.vue' /* webpackChunkName: "pages/news/news-list" */))
const _557908b6 = () => interopDefault(import('../pages/order/check-order.vue' /* webpackChunkName: "pages/order/check-order" */))
const _c79a2920 = () => interopDefault(import('../pages/order/check-shop-order.vue' /* webpackChunkName: "pages/order/check-shop-order" */))
const _5594f73e = () => interopDefault(import('../pages/register/index-dev.vue' /* webpackChunkName: "pages/register/index-dev" */))
const _fd97edc2 = () => interopDefault(import('../pages/teacher/teacher-detail/index.vue' /* webpackChunkName: "pages/teacher/teacher-detail/index" */))
const _4796d028 = () => interopDefault(import('../pages/wx-login/middle-page.vue' /* webpackChunkName: "pages/wx-login/middle-page" */))
const _257950d2 = () => interopDefault(import('../pages/agreement/components/ColumnName.vue' /* webpackChunkName: "pages/agreement/components/ColumnName" */))
const _690c4ebe = () => interopDefault(import('../pages/agreement/components/header.vue' /* webpackChunkName: "pages/agreement/components/header" */))
const _35625b09 = () => interopDefault(import('../pages/concat-us/components/concat-us.vue' /* webpackChunkName: "pages/concat-us/components/concat-us" */))
const _db59099c = () => interopDefault(import('../pages/concat-us/components/message-board.vue' /* webpackChunkName: "pages/concat-us/components/message-board" */))
const _2c07fce4 = () => interopDefault(import('../pages/cooperation-exchange/components/course-list.vue' /* webpackChunkName: "pages/cooperation-exchange/components/course-list" */))
const _dc308a44 = () => interopDefault(import('../pages/course/components/course-class.vue' /* webpackChunkName: "pages/course/components/course-class" */))
const _3d03ab92 = () => interopDefault(import('../pages/course/components/course-class-card/index.vue' /* webpackChunkName: "pages/course/components/course-class-card/index" */))
const _60a0b432 = () => interopDefault(import('../pages/course/components/course-face.vue' /* webpackChunkName: "pages/course/components/course-face" */))
const _217514b3 = () => interopDefault(import('../pages/course/components/course-plan.vue' /* webpackChunkName: "pages/course/components/course-plan" */))
const _89babe40 = () => interopDefault(import('../pages/course/components/filter-row/index.vue' /* webpackChunkName: "pages/course/components/filter-row/index" */))
const _f1f98298 = () => interopDefault(import('../pages/course/components/live-course.vue' /* webpackChunkName: "pages/course/components/live-course" */))
const _1298db42 = () => interopDefault(import('../pages/course/components/mixins/index.js' /* webpackChunkName: "pages/course/components/mixins/index" */))
const _1bd9619a = () => interopDefault(import('../pages/course/components/share-modal/index.vue' /* webpackChunkName: "pages/course/components/share-modal/index" */))
const _13a09c08 = () => interopDefault(import('../pages/course/components/special-class.vue' /* webpackChunkName: "pages/course/components/special-class" */))
const _18e9a542 = () => interopDefault(import('../pages/course/components/transition/index.js' /* webpackChunkName: "pages/course/components/transition/index" */))
const _0b8997c5 = () => interopDefault(import('../pages/course/components/video-course.vue' /* webpackChunkName: "pages/course/components/video-course" */))
const _40c33f40 = () => interopDefault(import('../pages/order/components/coupon-list.vue' /* webpackChunkName: "pages/order/components/coupon-list" */))
const _5fd8e910 = () => interopDefault(import('../pages/order/components/order-address.vue' /* webpackChunkName: "pages/order/components/order-address" */))
const _7d5787fb = () => interopDefault(import('../pages/order/components/wechat-modal.vue' /* webpackChunkName: "pages/order/components/wechat-modal" */))
const _56c055f5 = () => interopDefault(import('../pages/course/detail/components/top-detail.vue' /* webpackChunkName: "pages/course/detail/components/top-detail" */))
const _f097bafc = () => interopDefault(import('../pages/course/components/filter-row/components/content-type-sel.vue' /* webpackChunkName: "pages/course/components/filter-row/components/content-type-sel" */))
const _26c75cff = () => interopDefault(import('../pages/course/components/filter-row/components/price-sel.vue' /* webpackChunkName: "pages/course/components/filter-row/components/price-sel" */))
const _71255610 = () => interopDefault(import('../pages/course/components/filter-row/components/sort-title.vue' /* webpackChunkName: "pages/course/components/filter-row/components/sort-title" */))
const _4915d5ef = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _60a8b7bd = () => interopDefault(import('../pages/index/components/book-section/index.vue' /* webpackChunkName: "pages/index/components/book-section/index" */))
const _0e4d642a = () => interopDefault(import('../pages/index/components/course-list/index.vue' /* webpackChunkName: "pages/index/components/course-list/index" */))
const _634e402f = () => interopDefault(import('../pages/index/components/course-section/index.vue' /* webpackChunkName: "pages/index/components/course-section/index" */))
const _7b8c4f00 = () => interopDefault(import('../pages/index/components/free-section/index.vue' /* webpackChunkName: "pages/index/components/free-section/index" */))
const _31d9c5ed = () => interopDefault(import('../pages/index/components/government/index.vue' /* webpackChunkName: "pages/index/components/government/index" */))
const _60bb967a = () => interopDefault(import('../pages/index/components/index-banner/index.vue' /* webpackChunkName: "pages/index/components/index-banner/index" */))
const _4c8a5a00 = () => interopDefault(import('../pages/index/components/live-section/index.vue' /* webpackChunkName: "pages/index/components/live-section/index" */))
const _208fb494 = () => interopDefault(import('../pages/index/components/news-index/index.vue' /* webpackChunkName: "pages/index/components/news-index/index" */))
const _717a2724 = () => interopDefault(import('../pages/index/components/policy/index.vue' /* webpackChunkName: "pages/index/components/policy/index" */))
const _b93d3e2a = () => interopDefault(import('../pages/index/components/section-major/index.vue' /* webpackChunkName: "pages/index/components/section-major/index" */))
const _9a6ff3b8 = () => interopDefault(import('../pages/index/components/voice-section/index.vue' /* webpackChunkName: "pages/index/components/voice-section/index" */))
const _da5fa3f0 = () => interopDefault(import('../pages/index/components/course-section/child-major-modal/index.vue' /* webpackChunkName: "pages/index/components/course-section/child-major-modal/index" */))
const _5c4c7d12 = () => interopDefault(import('../pages/index/components/course-section/data.js' /* webpackChunkName: "pages/index/components/course-section/data" */))
const _380d8772 = () => interopDefault(import('../pages/index/components/voice-section/data.js' /* webpackChunkName: "pages/index/components/voice-section/data" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6d47fa2d,
    name: "about"
  }, {
    path: "/app-download",
    component: _2766ad2c,
    name: "app-download"
  }, {
    path: "/book",
    component: _f27deb42,
    name: "book"
  }, {
    path: "/certificate",
    component: _a7d22812,
    name: "certificate"
  }, {
    path: "/concat-us",
    component: _64fd30d2,
    name: "concat-us"
  }, {
    path: "/cooperation-exchange",
    component: _633a73ab,
    name: "cooperation-exchange"
  }, {
    path: "/course",
    component: _22b2435e,
    name: "course"
  }, {
    path: "/face-special",
    component: _46384c82,
    name: "face-special"
  }, {
    path: "/feedback",
    component: _a3033b0a,
    name: "feedback"
  }, {
    path: "/forget-pass",
    component: _7bc4d1d1,
    name: "forget-pass"
  }, {
    path: "/in-development",
    component: _630fe9c9,
    name: "in-development"
  }, {
    path: "/login",
    component: _7430a309,
    name: "login"
  }, {
    path: "/my-order",
    component: _2960663a,
    name: "my-order"
  }, {
    path: "/news",
    component: _9e8a046e,
    name: "news"
  }, {
    path: "/register",
    component: _3ed9d819,
    name: "register"
  }, {
    path: "/shopping-cart",
    component: _d7453ff6,
    name: "shopping-cart"
  }, {
    path: "/teacher",
    component: _68521f02,
    name: "teacher"
  }, {
    path: "/website-info",
    component: _3857da56,
    name: "website-info"
  }, {
    path: "/wx-login",
    component: _2bea0673,
    name: "wx-login"
  }, {
    path: "/about/detail-info",
    component: _5e18de88,
    name: "about-detail-info"
  }, {
    path: "/agreement/privacy-policy-YAO_XUE",
    component: _57689f5c,
    name: "agreement-privacy-policy-YAO_XUE"
  }, {
    path: "/agreement/privacy-policy-ZHI_YE",
    component: _1663857e,
    name: "agreement-privacy-policy-ZHI_YE"
  }, {
    path: "/agreement/user-agreement-YAO_XUE",
    component: _215a6b7a,
    name: "agreement-user-agreement-YAO_XUE"
  }, {
    path: "/agreement/user-agreement-ZHI_YE",
    component: _3008e96d,
    name: "agreement-user-agreement-ZHI_YE"
  }, {
    path: "/book/book-detail",
    component: _8bcfe7fc,
    name: "book-book-detail"
  }, {
    path: "/course/detail",
    component: _4d4ef065,
    name: "course-detail"
  }, {
    path: "/h5/course-class",
    component: _36c46de8,
    name: "h5-course-class"
  }, {
    path: "/h5/forget-pass",
    component: _da151036,
    name: "h5-forget-pass"
  }, {
    path: "/h5/login",
    component: _4e0c681d,
    name: "h5-login"
  }, {
    path: "/h5/register",
    component: _a695eaf6,
    name: "h5-register"
  }, {
    path: "/news/news-info",
    component: _490499df,
    name: "news-news-info"
  }, {
    path: "/news/news-list",
    component: _e2c32462,
    name: "news-news-list"
  }, {
    path: "/order/check-order",
    component: _557908b6,
    name: "order-check-order"
  }, {
    path: "/order/check-shop-order",
    component: _c79a2920,
    name: "order-check-shop-order"
  }, {
    path: "/register/index-dev",
    component: _5594f73e,
    name: "register-index-dev"
  }, {
    path: "/teacher/teacher-detail",
    component: _fd97edc2,
    name: "teacher-teacher-detail"
  }, {
    path: "/wx-login/middle-page",
    component: _4796d028,
    name: "wx-login-middle-page"
  }, {
    path: "/agreement/components/ColumnName",
    component: _257950d2,
    name: "agreement-components-ColumnName"
  }, {
    path: "/agreement/components/header",
    component: _690c4ebe,
    name: "agreement-components-header"
  }, {
    path: "/concat-us/components/concat-us",
    component: _35625b09,
    name: "concat-us-components-concat-us"
  }, {
    path: "/concat-us/components/message-board",
    component: _db59099c,
    name: "concat-us-components-message-board"
  }, {
    path: "/cooperation-exchange/components/course-list",
    component: _2c07fce4,
    name: "cooperation-exchange-components-course-list"
  }, {
    path: "/course/components/course-class",
    component: _dc308a44,
    name: "course-components-course-class"
  }, {
    path: "/course/components/course-class-card",
    component: _3d03ab92,
    name: "course-components-course-class-card"
  }, {
    path: "/course/components/course-face",
    component: _60a0b432,
    name: "course-components-course-face"
  }, {
    path: "/course/components/course-plan",
    component: _217514b3,
    name: "course-components-course-plan"
  }, {
    path: "/course/components/filter-row",
    component: _89babe40,
    name: "course-components-filter-row"
  }, {
    path: "/course/components/live-course",
    component: _f1f98298,
    name: "course-components-live-course"
  }, {
    path: "/course/components/mixins",
    component: _1298db42,
    name: "course-components-mixins"
  }, {
    path: "/course/components/share-modal",
    component: _1bd9619a,
    name: "course-components-share-modal"
  }, {
    path: "/course/components/special-class",
    component: _13a09c08,
    name: "course-components-special-class"
  }, {
    path: "/course/components/transition",
    component: _18e9a542,
    name: "course-components-transition"
  }, {
    path: "/course/components/video-course",
    component: _0b8997c5,
    name: "course-components-video-course"
  }, {
    path: "/order/components/coupon-list",
    component: _40c33f40,
    name: "order-components-coupon-list"
  }, {
    path: "/order/components/order-address",
    component: _5fd8e910,
    name: "order-components-order-address"
  }, {
    path: "/order/components/wechat-modal",
    component: _7d5787fb,
    name: "order-components-wechat-modal"
  }, {
    path: "/course/detail/components/top-detail",
    component: _56c055f5,
    name: "course-detail-components-top-detail"
  }, {
    path: "/course/components/filter-row/components/content-type-sel",
    component: _f097bafc,
    name: "course-components-filter-row-components-content-type-sel"
  }, {
    path: "/course/components/filter-row/components/price-sel",
    component: _26c75cff,
    name: "course-components-filter-row-components-price-sel"
  }, {
    path: "/course/components/filter-row/components/sort-title",
    component: _71255610,
    name: "course-components-filter-row-components-sort-title"
  }, {
    path: "/",
    component: _4915d5ef,
    name: "index",
    children: [{
      path: "components/book-section",
      component: _60a8b7bd,
      name: "index-components-book-section"
    }, {
      path: "components/course-list",
      component: _0e4d642a,
      name: "index-components-course-list"
    }, {
      path: "components/course-section",
      component: _634e402f,
      name: "index-components-course-section"
    }, {
      path: "components/free-section",
      component: _7b8c4f00,
      name: "index-components-free-section"
    }, {
      path: "components/government",
      component: _31d9c5ed,
      name: "index-components-government"
    }, {
      path: "components/index-banner",
      component: _60bb967a,
      name: "index-components-index-banner"
    }, {
      path: "components/live-section",
      component: _4c8a5a00,
      name: "index-components-live-section"
    }, {
      path: "components/news-index",
      component: _208fb494,
      name: "index-components-news-index"
    }, {
      path: "components/policy",
      component: _717a2724,
      name: "index-components-policy"
    }, {
      path: "components/section-major",
      component: _b93d3e2a,
      name: "index-components-section-major"
    }, {
      path: "components/voice-section",
      component: _9a6ff3b8,
      name: "index-components-voice-section"
    }, {
      path: "components/course-section/child-major-modal",
      component: _da5fa3f0,
      name: "index-components-course-section-child-major-modal"
    }, {
      path: "components/course-section/data",
      component: _5c4c7d12,
      name: "index-components-course-section-data"
    }, {
      path: "components/voice-section/data",
      component: _380d8772,
      name: "index-components-voice-section-data"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
