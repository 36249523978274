/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:13:17
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-10-28 11:52:39
 */

//  搜索类型
export const SEARCH_TYPE_ENUM = {
  COURSE: {
    value: 1,
    desc: '课程'
  }
  // LECTURER: {
  //   value: 2,
  //   desc: '师资'
  // },
  // NEWS: {
  //   value: 3,
  //   desc: '资讯'
  // },
  // BOOK: {
  //   value: 4,
  //   desc: '商城'
  // }
};

// 轮播图位置
export const PIC_LOCATION_ENUM = {
  PC_INDEX_BANNER: {
    value: 4,
    desc: 'PC官网首页-轮播图'
  },
  PC_INDEX_ACTIVITY: {
    value: 5,
    desc: 'PC官网首页-最新活动图'
  },
  PC_COURSE_BANNER: {
    value: 6,
    desc: 'PC官网-课程-轮播图'
  }
};

// 轮播图类型
export const PIC_TYPE_ENUM = {
  VIDEO: {
    value: 1,
    desc: '视频课'
  },
  LIVE: {
    value: 2,
    desc: '直播课'
  },
  CLAZZ: {
    value: 3,
    desc: '精品班'
  },
  FACE: {
    value: 4,
    desc: '面授班'
  },
  PLAN: {
    value: 5,
    desc: '课程体系'
  },
  ONLY_SHOW: {
    value: 6,
    desc: '仅展示'
  },
  URL: {
    value: 7,
    desc: '网址'
  },
  EXAMINATION: {
    value: 8,
    desc: '试卷'
  },
  LIBRARY: {
    value: 9,
    desc: '精品题库'
  },
  GOODS: {
    value: 10,
    desc: '图书商品'
  },
  QUESTION_HOME: {
    value: 11,
    desc: '题库首页'
  }
};

// 获取验证码事件类型
export const SMS_VERIFY_ACTION_TYPE_ENUM =
{
  NONE: {
    value: 0,
    desc: '无'
  },
  BIND_THIRD_ACCOUNT: {
    value: 1,
    desc: '绑定第三方账户'
  },
  SMS_VERIFY_LOGIN: {
    value: 2,
    desc: '短信验证码登录'
  },
  SMS_VERIFY_RESET_PWD: {
    value: 3,
    desc: '修改密码'
  },
  SMS_VERIFY_REGISTER: {
    value: 4,
    desc: '账号注册'
  },
  UPDATE_PHONE: {
    value: 5,
    desc: '更换手机号'
  }
};

// 获取验证码类型
export const SMS_VERIFY_TYPE_ENUM =
{
  REGISTER: {
    value: 5,
    desc: '账号注册'
  },
  UPDATE_PWD: {
    value: 10,
    desc: '修改密码'
  },
  AUTHENTICATION: {
    value: 15,
    desc: '身份验证'
  },
  VERIFICATION_CODE_LOGIN: {
    value: 20,
    desc: '验证码登录'
  }
};

export const NEWS_TYPE_ENUM = {
  POLICY: {
    value: 1,
    desc: '政策法规'
  },
  GOVERNMENT: {
    value: 2,
    desc: '党政建设'
  }
}

export const APP_ENV_TYPE_ENUM = {
  ZHI_YE: {
    value: 'ZHI_YE',
    desc: '执业药师'
  },
  YAO_XUE: {
    value: 'YAO_XUE',
    desc: '药学专业人员'
  }
};

export const NEWS_SUB_TYPE_ENUM = {
  HANGY_YE_XIN_XI: {
    value: 1002,
    desc: '行业信息'
  },
  ZHENG_CE_FA_GUI: {
    value: 1006,
    desc: '政策法规'
  },
  CHANG_JIAN_WEN_TI: {
    value: 1011,
    desc: '常见问题'
  },
  WANG_ZHAN_GAI_KUANG: {
    value: 1013,
    desc: '网站概况'
  },
  LIAN_XI_WO_MEN: {
    value: 1014,
    desc: '联系我们'
  },
  KAI_FA_XIE_HUI: {
    value: 1020,
    desc: '中国民间中医医药研究开发协会'
  },
  SHOU_FA_FEN_HUI: {
    value: 1021,
    desc: '北派筋骨辩证手法分会'
  }
}

export default {
  SEARCH_TYPE_ENUM,
  PIC_LOCATION_ENUM,
  PIC_TYPE_ENUM,
  SMS_VERIFY_ACTION_TYPE_ENUM,
  SMS_VERIFY_TYPE_ENUM,
  NEWS_TYPE_ENUM,
  APP_ENV_TYPE_ENUM,
  NEWS_SUB_TYPE_ENUM
};
