<!--
 * @Description:
 * @Author: hanyu
 * @Date: 2020-12-23 20:39:30
 * @LastEditTime: 2021-01-07 09:04:53
 * @LastEditors: hanyu
-->
<!--
 * @Description: 资讯和帮助中心
 * @Author: zhuoda
-->
<template>
  <div class="root">
    <!-- 最顶部 -->
    <!--    <HeaderBar />-->
    <!-- 首页、全部课程 等 、个人中心 -->
    <!--    <MainHeader />-->
    <DefaultHeader ref="defaultHeaderRef"/>
    <!-- 浮动客服 -->
    <FloatCustomerService ref="floatCustomerService" />
    <!-- 登录弹窗 -->
    <Account ref="accountRef" />
    <!-- 浏览器校验弹窗 -->
    <BrowserValidModal></BrowserValidModal>
    <div class="page-content" :style="{'min-height': `calc(100vh - ${(footerHeight + headerHeight) + 'px' })`}">
      <nuxt keep-alive :keep-alive-props="{ include: includeArr }" />
    </div>
    <!-- 底部客服 -->
    <Customer></Customer>
    
    <IndexFooter ref="indexFooterRef"/>
  </div>
</template>

<script>
import DefaultHeader from '@/components/default-header';
import HeaderBar from '@/components/header-bar';
import MainHeader from '@/components/header/main-header';
import Account from '@/components/account';
import FloatCustomerService from '@/components/float-customer-service';
import IndexFooter from '@/components/index-footer';
import BrowserValidModal from '@/components/browser-valid-modal';
import buryPoint from '@/mixins/bury-point';
import Customer from '@/components/customer';

export default {
  name: 'NewsLayout',
  components: {
    HeaderBar, MainHeader, Account, FloatCustomerService, IndexFooter, DefaultHeader, BrowserValidModal, Customer
  },
  filters: {},
  mixins: [buryPoint],
  props: {},
  data () {
    return {
      includeArr: ['Teacher'],
      footerHeight: 0,
      headerHeight: 0
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.headerHeight = this.$refs.defaultHeaderRef.$el.offsetHeight;
        this.footerHeight = this.$refs.indexFooterRef.$el.offsetHeight;
      }, 200);
      
    })
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {},
  head () {
    return {
      link: [
        { rel: 'stylesheet', href: 'https://cdn.bootcss.com/twitter-bootstrap/3.3.7/css/bootstrap.min.css' }
      ]
    };
  }
};
</script>
<style lang='less' scoped>
.page-content {
  background: #f7f7f7;
}
</style>
