<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-20 14:49:38
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-24 16:34:49
-->
<template>
  <div class="root">
    <!--    <HeaderBar></HeaderBar>-->
    <!-- 浮动客服 -->
    <FloatCustomerService></FloatCustomerService>
    <!-- 登录弹窗 -->
    <Account ref="accountRef"></Account>
    <!-- 浏览器校验弹窗 -->
    <BrowserValidModal></BrowserValidModal>
    <!-- 底部客服 -->
    <Customer></Customer>
    <nuxt />
  </div>
</template>

<script>
import HeaderBar from '@/components/header-bar';
import FloatCustomerService from '@/components/float-customer-service';
import Account from '@/components/account';
import Customer from '@/components/customer';
import BrowserValidModal from '@/components/browser-valid-modal';
import buryPoint from '@/mixins/bury-point';

export default {
  name: 'DefaultLayouts',
  components: {
    HeaderBar,
    FloatCustomerService,
    Account,
    Customer,
    BrowserValidModal
  },
  filters: {},
  mixins: [buryPoint],
  props: {},
  data () {
    return {
      showTopAd: false // 是否显示顶部广告
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.showTopAd = this.$route.name == 'index';
  },
  methods: {
    // 进入题库页面
    onQuestion () {
      this.$pcRouter.push({ path: 'center/my-question?tabId=4' });
    }
  }
};
</script>
<style lang='less' scoped>
.head-top {
  width: 100%;
}
</style>
