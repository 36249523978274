<!--
 * @Description:  浮动客服
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-25 20:21:25
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-09-26 08:55:11
-->
<template>
  <div
      id="onlineService"
      class="online-service"
  >
    <div class="service-menu">
      <div class="menu-item tel" @click="openChat">
        联系客服
        <div class="menu-item-content tel">
          <p> 工作时间（工作日）<br>{{ this.$store.state.user.systemInfo.workStartTime }}-{{
              this.$store.state.user.systemInfo.workEndTime
            }}</p>
        </div>
      </div>
      <!-- <div class="menu-item weChat">
        咨询电话
        <div class="menu-item-content tel">
          <p>
            {{ this.$store.state.user.systemInfo.customerServicePhone }}
          </p>
        </div>
      </div> -->
<!--      <div class="menu-item app">-->
<!--        App下载-->
<!--        <div class="menu-item-content">-->
<!--          <div class="code-img">-->
<!--            <img :src="`/images/index/online/android-app-qr_${type}.png`" />-->
<!--            <p>安卓手机</p>-->
<!--          </div>-->
<!--          <div class="code-img">-->
<!--            <img :src="`/images/index/online/ios-app-qr_${type}.png`" />-->
<!--            <p>苹果手机</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import appCode from '@/assets/image/common/code-app.png';
import offcialCode from '@/assets/image/common/code-offcial.jpg';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';

export default {
  name: 'FloatCustomerService',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      appCode,
      offcialCode
    };
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo;
    },
    type () {
      return process.env.TYPE;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    window.onscroll = function () {
      const onlineService = document.getElementById('onlineService');
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const nTarget = scrollTop + 310;
      if (onlineService) {
        move(onlineService, parseInt(nTarget));
      }
    };

    let oMoveTimer = null;

    function move (oDom, nTarget) {
      clearInterval(oMoveTimer);
      oMoveTimer = setInterval(function () {
        let speed = (nTarget - oDom.offsetTop) / 6;
        speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);
        if (oDom.offsetTop === nTarget) {
          clearInterval(oMoveTimer);
          return;
        }
        oDom.style.top = oDom.offsetTop + speed + 'px';
      }, 30);
    }
  },
  methods: {
    // 返回顶部
    toTop () {
      $('body,html').animate({ scrollTop: 0 }, 500);
    },
    // 打开客服聊天
    openChat() {
      if(window.qimoChatClick){
        window.qimoChatClick();
      }
    }
  }
};
</script>
<style lang='less' scoped>
@import "./float-customer-service-mini.less";
</style>
